import { RendererProps } from '../types'
import Context from './AppContext'
import React, { useContext, ComponentType, useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { ErrorBoundary, DeadComp } from './ErrorBoundary'
import { getDisplayedId, getFullId, getDefaultCompId } from '@wix/thunderbolt-commons'
import { PropsMap, ActionProps } from '@wix/thunderbolt-symbols'

type StructureComponentProps = { id: string; displayedItemId?: string }

const useDisplayedProps = (displayedId: string) => {
	const { props: propsStore } = useContext(Context)
	const props = { ...propsStore.get(getFullId(displayedId)), ...propsStore.get(displayedId) }
	const functionProps = Object.keys(props).filter((key) => typeof props[key] === 'function')
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fixedFunctionProps = useMemo(
		() =>
			functionProps.reduce((acc, prop) => {
				return {
					...acc,
					[prop]: (event: any, ...rest: Array<any>) => {
						if (event?.nativeEvent instanceof Event) {
							event.compId = displayedId
						}
						return props[prop](event, ...rest)
					},
				}
			}, {} as PropsMap),
		[functionProps] // eslint-disable-line react-hooks/exhaustive-deps
	)
	return { ...props, ...fixedFunctionProps }
}

const useStoresObserver = (id: string, displayedId: string): void => {
	const { structure: structureStore, props: propsStore, compsLifeCycle } = useContext(Context)

	const [, setTick] = useState(0)
	const forceUpdate = useCallback(() => setTick((tick) => tick + 1), [])

	const notifyOnMount = () => {
		compsLifeCycle.notifyCompDidMount(id, displayedId)
	}
	const subscribeToStores = () => {
		const stores = [propsStore, structureStore]
		const unSubscribers: Array<() => void> = []
		stores.forEach((store) => {
			const unsubscribe = store.subscribeById(displayedId, forceUpdate)
			unSubscribers.push(unsubscribe)
			if (displayedId !== id) {
				unSubscribers.push(store.subscribeById(id, forceUpdate))
			}
		})

		return () => {
			unSubscribers.forEach((cb) => cb())
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(notifyOnMount, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(subscribeToStores, [id, displayedId])
}

const StructureComponent: ComponentType<StructureComponentProps> = ({ id, displayedItemId = '' }) => {
	const {
		structure: structureStore,
		props: propsStore,
		comps,
		translate,
		logger,
		compEventsRegistrar,
		compControllers,
		createCompControllerArgs,
	}: RendererProps = useContext(Context)
	let displayedId = displayedItemId ? getDisplayedId(id, displayedItemId) : id

	const compStructure = structureStore.get(displayedId) || structureStore.get(id)
	if (!compStructure) {
		throw new Error(`Component is missing from structure. displayedId: ${displayedId}. id: ${id}`)
	}
	const { componentType, uiType } = compStructure
	const compClassType = uiType ? `${componentType}_${uiType}` : componentType
	const Comp = comps[compClassType]

	useStoresObserver(id, displayedId)
	const controllerActionsReference = useRef<ActionProps | null>(null)
	if (compControllers[compClassType]) {
		if (!controllerActionsReference.current) {
			controllerActionsReference.current = compControllers[compClassType](createCompControllerArgs(displayedId))
		}

		compEventsRegistrar.registerController(displayedId, controllerActionsReference.current)
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const compProps = displayedId === id ? propsStore.get(id) : useDisplayedProps(displayedId)

	displayedId = getDefaultCompId(displayedId)

	const components = compStructure!.components

	const children = useCallback(
		(itemId?: string) =>
			(components || []).map((childId: string) => {
				const childProps = propsStore.get(childId)
				const defaultChildId = getDefaultCompId(childId)

				return (
					<StructureComponent
						displayedItemId={displayedItemId || itemId}
						id={childId}
						key={childProps?.key || defaultChildId}
					/>
				)
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[components, displayedItemId]
	)

	// TODO: Remove the fallback once all components are implemented
	const component = Comp ? (
		<Comp translate={translate} {...compProps} id={displayedId}>
			{children}
		</Comp>
	) : (
		<DeadComp id={displayedId}>{children()}</DeadComp>
	)

	return (
		<ErrorBoundary
			id={displayedId}
			logger={logger}
			recursiveChildren={children}
			Component={Comp}
			compClassType={compClassType}
			sentryDsn={compProps?.sentryDsn}
		>
			{component}
		</ErrorBoundary>
	)
}

export default StructureComponent
