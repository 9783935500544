import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { IReporterApi, ReporterSiteConfig, ReporterState } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'
import { SessionManagerSymbol, ISessionManager } from 'feature-session-manager'

import { name } from './symbols'
import { FeatureStateSymbol, SiteFeatureConfigSymbol, ExperimentsSymbol, Experiments } from '@wix/thunderbolt-symbols'
import { setState } from './utils'
import { enrichEventOptions } from './event-options'

const reporterFactory = (
	featureState: IFeatureState<ReporterState>,
	siteConfig: ReporterSiteConfig,
	experiments: Experiments,
	sessionManager: ISessionManager
): IReporterApi => ({
	trackEvent: async (event) => {
		const { isAdapterInitialized } = featureState.get() || {}
		if (isAdapterInitialized) {
			const { eventName, params, options = {} } = event
			const eventOptions = enrichEventOptions(options, experiments, siteConfig)
			const eventParams = {
				...params,
				visitorId: sessionManager.getVisitorId(),
			}
			const api = await import('./api' /* webpackChunkName: "reporter-api" */)
			api.trackEvent(eventName, eventParams, eventOptions)
		} else {
			setState(featureState, { didPreventEvents: true })
		}
	},
})

export const Reporter = withDependencies(
	[named(FeatureStateSymbol, name), named(SiteFeatureConfigSymbol, name), ExperimentsSymbol, SessionManagerSymbol],
	reporterFactory
)
